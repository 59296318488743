import React from "react";
import Layout from "../components/layout";
import { graphql } from "gatsby";
import * as PropTypes from "prop-types";
import ReactMarkdown from "react-markdown";
import styled from "styled-components";
import C from "../../colors";
import SideAds from "../components/Ads/sideads";
import MobileAds from "../components/Ads/mobileads";
import SEO from "../components/seo";
import LocalizedLink from "../components/localizedLink";
import { FormattedMessage } from "react-intl";

const BlogPage = styled.div`
  max-width: 800px;
  margin: 0 auto;
  padding: 20px 0;

  .hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
    box-sizing: content-box;
    height: 0;
    overflow: visible;
  }

  @media (min-width: 850px) {
    .content-wrap {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
      -webkit-flex-direction: row;
      -ms-flex-direction: row;
      flex-direction: row;
      max-width: 75% !important;
    }
  }

  .content-wrap {
    position: relative;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  @media (min-width: 1100px) {
    .content-wrap,
    .site-footer {
      margin-left: 0;
      margin-right: auto;
    }
  }

  @media (min-width: 700px) {
    .content-wrap {
      -webkit-transition: margin-right, margin-left 0.45s ease 75ms;
      transition: margin-right, margin-left 0.45s ease 75ms;
      margin-right: 0;
    }
  }

  .blogtitle {
    color: ${C.dark};
    font-size: 2.2rem;
    margin-top: 10px;
    padding-bottom: 8px;
    @media (max-width: 600px) {
      font-size: 2rem;
      line-height: 1.6;
      padding: 0px;
      margin-bottom: 0px;
      margin-top: 0px;
    }
  }

  /* pagination */

  .paginationList {
    display: flex;
    padding-left: 0;
    margin-top: 10px;
    margin-bottom: 0;
    padding-bottom: 0;
    padding-top: 20px;
    margin-left: 0;
    list-style: none;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
      padding-top: 40px;
    }

    a {
      padding: 0.5em;
      margin-right: 0.3em;
      color: ${C.blue};
      font-size: 17px;

      &:hover {
        color: ${C.gray2};
      }
    }
  }

  /* pagination */

  .md {
    font-size: 17px;
    line-height: 1.75;
    color: ${C.dark};
    h1,
    h2,
    h3 {
      color: ${C.darker};
    }
    h1 {
      margin-bottom: 30px;
    }
    img {
      display: block;
      max-width: 100%;
      margin: 20px auto;
    }
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 1.6;
    }
  }
  .post-link {
    list-style: none;
    margin: 20px 0 50px;
    display: flex;
    .post-thumb {
    }
    .post-title {
    }

    a {
      font-size: 200%;
      color: ${C.darker};
    }
    img {
      float: left;
      max-width: 120px;
      margin-right: 20px;
      // margin-top: 10px;
      border-radius: 3px;
      cursor: pointer;
    }
    @media (max-width: 600px) {
      margin: 32px 0;
      img {
        max-width: 80px;
        margin-right: 15px;
        margin-top: 10px;
      }
      a {
        font-size: 150%;
      }
    }
  }
`;

const propTypes = {
  data: PropTypes.object.isRequired
};

export default class BlogPost extends React.Component {

  // componentWillMount() {
  //   if (typeof window !== 'undefined') {
  //     // do your checks; this will be re-invoked on client entry
      

  //   //sending the contentfulID to the LanguageSwitcher component: https://lage.us/Javascript-Pass-Variables-to-Another-Page.html
  //   const contentfulId = this.props.data.imageFallback.contentful_id;
  //   sessionStorage.setItem("ContentfulID", contentfulId);
  //   }
  // }

  render() {

    const contentfulId = this.props.data.imageFallback.contentful_id;

    let postTitle;
    let postDescription;
    //localize the SEO
    if (this.props.data.post.node_locale === "en-US") {
      postTitle = `${this.props.data.post.og_title} | EOS Go General Info`;
      postDescription = this.props.data.post.og_description;
    } else if (this.props.data.post.node_locale === "zh-CN") {
      postTitle = `${this.props.data.post.og_title} | EOS Go 详情介绍`;
      postDescription = this.props.data.post.og_description;
    }

    const postDate = this.props.data.post.date;
    const postContent = this.props.data.post.excerpt.excerpt;

    //adding fallback og_image to English locale for each post if image is not available.
    let ogImage;

    if (this.props.data.post.og_image.file !== null) {
      ogImage = `https:${this.props.data.post.og_image.file.url}`;
    } else {
      ogImage = `https:${this.props.data.imageFallback.og_image.file.url}`;
    }

    return (
      <Layout title="blog" contentfulID={contentfulId}>
        <SEO
          title={postTitle}
          description={postDescription}
          image={{
            src: ogImage
          }}
        />
        {/* <div>
                <h1>{titleTest}</h1>
                
              </div> */}
        <BlogPage>
          <div className="content-wrap">
            {/* SIDEBAR BEGINS */}

            <SideAds />

            {/* SIDEBAR ENDS */}

            <LocalizedLink to={`/info`}> &larr; <FormattedMessage id="head-back-posts" /> </LocalizedLink>
            <div className="hr" />
            {postDate}
            <br />
            <br />
            <div className="md">
              <ReactMarkdown source={postContent} />
            </div>
          </div>

          {/* MOBILE AD BEGINS */}
          <MobileAds />

          {/* MOBILE AD ENDS */}
        </BlogPage>
      </Layout>
    );
  }
}

BlogPost.propTypes = propTypes;

export const pageQuery = graphql`
  query($slug: String!, $id: String!, $node_locale: String!) {
    post: contentfulSeoPost(
      slug: { eq: $slug }
      node_locale: { eq: $node_locale }
    ) {
      contentful_id
      title
      node_locale
      date
      excerpt {
        excerpt
      }
      og_title
      og_description
      og_image {
        file {
          url
        }
      }
    }
    imageFallback: contentfulSeoPost(
      node_locale: { eq: "en-US" }
      contentful_id: { eq: $id }
    ) {
      contentful_id
      node_locale
      og_image {
        file {
          url
        }
      }
    }
  }
`;
